.carousel {
  width: 100%;
  position: relative;
  padding-bottom: 40%;
  overflow: hidden; }
  .carousel.stacked {
    padding-bottom: 0 !important; }
  .carousel .left-btn,
  .carousel .right-btn {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 12px 20px 15px 20px;
    font-size: 38px;
    font-weight: 800;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.1); }
    @media all and (min-width: 786px) {
      .carousel .left-btn,
      .carousel .right-btn {
        display: block; } }
    .carousel .left-btn:hover, .carousel .left-btn:focus,
    .carousel .right-btn:hover,
    .carousel .right-btn:focus {
      background-color: rgba(0, 0, 0, 0.7); }
    .carousel .left-btn.stacked,
    .carousel .right-btn.stacked {
      display: none; }
  .carousel .left-btn {
    left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .carousel .right-btn {
    right: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .carousel .dots {
    position: absolute;
    bottom: 0;
    padding-bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2; }
    .carousel .dots.stacked {
      display: none; }
    .carousel .dots .dot {
      display: inline-block;
      border-radius: 100%;
      width: 14px;
      height: 14px;
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid #ddd;
      cursor: pointer;
      margin: 0 5px;
      transition: background-color 400ms; }
      .carousel .dots .dot:hover, .carousel .dots .dot:focus {
        background-color: rgba(0, 0, 0, 0.6); }
      .carousel .dots .dot.active {
        background-color: white; }

.carousel-item {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-size: cover;
  background-position: center; }
  .carousel-item.stacked {
    position: relative;
    top: auto;
    left: auto;
    height: auto; }
  .carousel-item.active {
    z-index: 1; }
  .carousel-item.appear-from-left {
    animation-name: appear-from-left;
    animation-duration: 700ms;
    animation-timing-function: ease-out; }
  .carousel-item.appear-from-right {
    animation-name: appear-from-right;
    animation-duration: 700ms;
    animation-timing-function: ease-out; }
  .carousel-item.hide-to-left {
    animation-name: hide-to-left;
    animation-duration: 700ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; }
  .carousel-item.hide-to-right {
    animation-name: hide-to-right;
    animation-duration: 700ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; }
  .carousel-item.fade-in {
    animation-name: fade-in;
    animation-duration: 700ms;
    animation-fill-mode: forwards; }
  .carousel-item.fade-out {
    animation-name: fade-out;
    animation-duration: 700ms;
    animation-fill-mode: forwards; }

@keyframes appear-from-left {
  from {
    transform: translateX(-110%); }
  to {
    transform: translateX(0); } }

@keyframes appear-from-right {
  from {
    transform: translateX(110%); }
  to {
    transform: translateX(0); } }

@keyframes hide-to-left {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(-110%); } }

@keyframes hide-to-right {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(110%); } }

@keyframes fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }
